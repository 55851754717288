.wrapper {
  & :global(.inner-wrapper) {
    @apply box-content px-4 mx-auto md:px-8 max-w-8xl;
  }

  &:global(.bg-color-good-light-green) {
    @apply bg-good-green-10;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-good-light-blue) {
    @apply bg-good-blue-5;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-white) {
    @apply bg-white;
    @apply py-10 md:py-16;
  }

  &:global(.layout-fourth .columns),
  &:global(.layout-third .columns),
  &:global(.layout-half .columns),
  &:global(.layout-giftPage .columns) {
    @apply grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-2;
  }

  &:global(.layout-fourth .columns) {
    @apply md:grid-cols-2 xl:grid-cols-4;
  }

  &:global(.layout-third .columns) {
    @apply md:grid-cols-2 lg:grid-cols-3;
  }

  &:global(.layout-half .columns) {
    @apply md:grid-cols-2 lg:grid-cols-2;
  }

  &:global(.has-white-theme .columns > .blurb) {
    @apply bg-white p-4 md:p-6 lg:p-8;
  }
}
