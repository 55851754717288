.wrapper {
  @apply flex items-center mb-4 md:mb-6 lg:mb-8 text-black-100;

  & :global(.lead) {
    @apply max-w-1xl;
  }

  &:global(.no-icon) {
    @apply block;

    & h2 {
      @apply mb-3;
    }
  }

  &:global(.center) {
    & h2,
    & :global(.lead) {
      @apply mx-auto text-center;
    }
  }

  &:global(.inner-column) {
    @apply max-w-1xl mx-auto;
  }
}
